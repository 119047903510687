import { WORDS } from '../constants/wordlist'
import { VALIDGUESSES } from '../constants/validGuesses'

export const isWordInWordList = (word: string) => {
  return (
    WORDS.includes(word.toLowerCase()) ||
    VALIDGUESSES.includes(word.toLowerCase())
  )
}

export const isWinningWord = (word: string) => {
  return solution === word
}

export const getRandomWord = () => {
  // January 1, 2022 Game Epoch
  // const epochMs = new Date('January 1, 2022 00:00:00').valueOf()
  // const now = Date.now()
  // const msInDay = 86400000
  // const index = Math.floor((now - epochMs) / msInDay)
  // const nextday = (index + 1) * msInDay + epochMs
  const wordindex = Math.floor(Math.random() * WORDS.length);

  return {
    solution: WORDS[wordindex].toUpperCase(),
    solutionIndex: wordindex,
    tomorrow: 0,
  }
}

export const getNewWord = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let { solution, solutionIndex, tomorrow } = getRandomWord()
}

export let { solution, solutionIndex, tomorrow } = getRandomWord()
